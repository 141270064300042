<template>
  <div class="address-win">
    <el-dialog
      :visible.sync="show"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="close"
    >
      <div class="dialog-win">
        <div class="dialog-left">
          <img
            :style="{ width: '100%', height: '100%' }"
            src="@/assets/images/create_account.svg"
          />
        </div>
        <div class="dialog-right">
          <div class="dialog-close" @click="close">
            <i class="el-icon-close"></i>
          </div>
          <div class="align-center" style="height:100%">
            <div class="login-content">
              <div class="login-title">
                <span>Create an account</span>
              </div>
              <el-form
                ref="ruleForm1"
                :model="ruleForm1"
                :rules="rules1"
                class="demo-ruleForm"
                label-position="top"
                v-show="firstStep"
                :hide-required-asterisk="true"
              >
                <el-form-item prop="email" label="Email address">
                  <el-input
                    v-model="ruleForm1.email"
                    :disabled="ncvisible"
                    placeholder="name@company.com"
                  >
                  </el-input>
                  <template slot="error" slot-scope="scope">
                    <span class="form__error_icon_tip">
                      <i class="el-icon-error"></i>
                      <span>{{ scope.error }}</span>
                    </span>
                  </template>
                </el-form-item>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item prop="firstName" label="First name">
                      <el-input
                        v-model="ruleForm1.firstName"
                        :disabled="ncvisible"
                        placeholder="Enter your first name"
                      ></el-input>
                      <template slot="error" slot-scope="scope">
                        <span class="form__error_icon_tip">
                          <i class="el-icon-error"></i>
                          <span>{{ scope.error }}</span>
                        </span>
                      </template>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item prop="lastName" label="Last name">
                      <el-input
                        v-model="ruleForm1.lastName"
                        :disabled="ncvisible"
                        placeholder="Enter your last name"
                      ></el-input>
                      <template slot="error" slot-scope="scope">
                        <span class="form__error_icon_tip">
                          <i class="el-icon-error"></i>
                          <span>{{ scope.error }}</span>
                        </span>
                      </template>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item prop="password" label="Password">
                  <el-input
                    v-model="ruleForm1.password"
                    :disabled="ncvisible"
                    ref="password1"
                    :type="pwdType_password1"
                    placeholder="Enter your password"
                  >
                    <template slot="suffix">
                      <span class="showPassWord" @click.stop="changePwdType('password1')">
                        <img
                          class="eyeIcon"
                          v-show="pwdType_password1 == 'text'"
                          src="~assets/images/openEyes.svg"
                        />
                        <img
                          class="eyeIcon"
                          v-show="pwdType_password1 !== 'text'"
                          src="~assets/images/closeEyes.svg"
                        />
                      </span>
                    </template>
                  </el-input>
                  <template slot="error" slot-scope="scope">
                    <span class="form__error_icon_tip">
                      <i class="el-icon-error"></i>
                      <span>{{ scope.error }}</span>
                    </span>
                  </template>
                </el-form-item>
                <div
                  class="mgb20 passwordHint"
                  v-show="!ruleForm1.password || ruleForm1.password.length == 0"
                >
                  <div class="passHint">
                    <span class="redioSpan"></span>
                    <span>At least 1 letter</span>
                  </div>
                  <div class="passHint">
                    <span class="redioSpan"></span>
                    <span>At least 1 number</span>
                  </div>
                  <div class="passHint">
                    <span class="redioSpan"></span>
                    <span>At least 8 characters</span>
                  </div>
                </div>
                <div
                  class="mgb20 passwordHint"
                  v-show="ruleForm1.password && ruleForm1.password.length > 0"
                >
                  <div :class="passHint.hint1 ? 'passHint passHint-g' : 'passHint passHint-r'">
                    <i v-if="passHint.hint1" class="el-icon-success"></i>
                    <i v-else class="el-icon-error"></i>
                    <span class="passHint-txt">At least 1 letter</span>
                  </div>
                  <div :class="passHint.hint2 ? 'passHint passHint-g' : 'passHint passHint-r'">
                    <i v-if="passHint.hint2" class="el-icon-success"></i>
                    <i v-else class="el-icon-error"></i>
                    <span class="passHint-txt">At least 1 number</span>
                  </div>
                  <div :class="passHint.hint3 ? 'passHint passHint-g' : 'passHint passHint-r'">
                    <i v-if="passHint.hint3" class="el-icon-success"></i>
                    <i v-else class="el-icon-error"></i>
                    <span class="passHint-txt">At least 8 characters</span>
                  </div>
                </div>
                <div class="agreement">
                  <el-form-item prop="agreement">
                    <el-checkbox-group v-model="ruleForm1.agreement" style="line-height: 14px;">
                      <el-checkbox name="agreement">
                        I agree with the
                        <a
                          class="agreeLink"
                          href="https://app.termly.io/document/terms-of-service/658e05ae-a1fc-48e3-8b5a-6e59c7156558"
                          target="_blank"
                        >
                          Terms and Conditions</a
                        >
                        <span>and</span>
                        <a
                          class="agreeLink"
                          href="https://app.termly.io/document/privacy-policy/383b32a7-2031-4bec-8177-3f251368ce6d"
                          target="_blank"
                          >Privacy Policy</a
                        >
                      </el-checkbox>
                    </el-checkbox-group>
                    <template slot="error" slot-scope="scope">
                      <span class="form__error_icon_tip">
                        <i class="el-icon-error"></i>
                        <span>{{ scope.error }}</span>
                      </span>
                    </template>
                  </el-form-item>
                </div>
              </el-form>
              <div class="registerBtn">
                <el-button
                  id="AB_SignUpFinishButton"
                  @click="submitValid('ruleForm1')"
                  :loading="registerLoading"
                  type="primary"
                  :disabled="!ruleForm1.agreement.length"
                >
                  Sign Up
                </el-button>
                <div v-show="ncvisible">
                  <div id="ncRegister" ref="ncRegister"></div>
                </div>
              </div>
              <div class="login_in fwn">
                <span>Already have an account?</span>
                <span id="AB_SignInTextLink" class="loginTxt" @click="login">Log In</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { px2rem, isAtDSTime } from '~/libs/tool'
import countryInitList from '@/components/International-sortPickerList/list.js'
import teamQuoteMixin from '~/libs/teamQuoteMixin.js'
const Cookie = process.client ? require('js-cookie') : undefined
export default {
  name: 'Register',
  middleware: 'authenticated',
  mixins: [teamQuoteMixin],
  data() {
    const validateFirstName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please enter your first name'))
      } else {
        callback()
      }
    }
    const validateLastName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please enter your last name'))
      } else {
        callback()
      }
    }
    const validateEmail = (rule, value, callback) => {
      // eslint-disable-next-line no-useless-escape
      const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      // const reg = /^([a-zA-Z]|[0-9])(\w|\-|\.)+@[a-zA-Z0-9](\w|\-)+((.|-)([a-zA-Z]{2,4}))((.|-)([a-zA-Z]{2,4}))?$/
      if (value === '' || !reg.test(value)) {
        callback(new Error('Please enter the correct email address'))
      } else {
        const data = {
          user: value
        }
        this.$detectionUser(data).then(res => {
          if (res.code !== 1) {
            callback(new Error('This account already exists, please log in directly'))
          } else {
            callback()
          }
        })
      }
    }
    const validatePass = (rule, value, callback) => {
      const include = /^(?![^a-zA-Z]+$)(?!\D+$)/.test(value)
      if (value === '') {
        callback(new Error('Please enter your password'))
      } else if (!include || value.length < 8) {
        callback(
          new Error(
            'A minimum 8 characters is required and they should be a mixture of letters and numbers'
          )
        )
      } else {
        if (this.ruleForm1.passwordTo !== '') {
          this.$refs.ruleForm1.validateField('passwordTo')
        }
        callback()
      }
    }
    return {
      pwdType_password1: 'password',
      pwdType_password2: 'password',
      show: false,
      countryList: countryInitList.list,
      countryOptions: countryInitList.list,
      registerLoading: false,
      getVerifyLoading: false,
      isgetCode: true,
      ncvisible: false,
      count: '',
      timer: null,
      firstStep: true,
      ruleForm1: {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        agreement: []
      },
      otherJob: '',
      flagImgSrc: undefined,
      rules1: {
        email: [{ required: true, validator: validateEmail, trigger: 'blur' }],
        password: [{ required: true, validator: validatePass, trigger: 'blur' }],
        firstName: [
          {
            required: true,
            validator: validateFirstName,
            trigger: 'blur'
          }
        ],
        lastName: [
          {
            required: true,
            validator: validateLastName,
            trigger: 'blur'
          }
        ],
        agreement: [
          {
            type: 'array',
            required: true,
            message: 'Please read and agree to the agreement',
            trigger: 'change'
          }
        ]
      },
      tableShow: {
        currentPage: 1,
        pageSize: 10,
        listCount: 0
      },
      passHint: {
        hint1: false,
        hint2: false,
        hint3: false
      },
      nvcVal: null,
      nvc: null,
      registerTime: null
    }
  },
  watch: {
    '$store.state.register': function(val) {
      this.show = val
      let inviterEmail = localStorage.getItem('createTeam/inviterEmail') || ''
      this.ruleForm1.email = inviterEmail ? inviterEmail : localStorage.getItem('mail') || ''
      if (val) {
        this.$nextTick(() => {
          this.awscinit()
        })
      } else {
        this.ncvisible = false
        this.nvcVal = null
      }
    },
    'ruleForm1.password': function(val) {
      if (val) {
        this.passHint.hint1 = /^(?![^a-zA-Z]+$)/.test(val)
        this.passHint.hint2 = /\d+/.test(val)
        this.passHint.hint3 = val.length > 7
      } else {
        this.passHint = {
          hint1: false,
          hint2: false,
          hint3: false
        }
      }
    },
    show: function(val) {
      if (!val) {
        this.firstStep = true
        this.$refs.ruleForm1.resetFields()
        this.flagImgSrc = undefined
        this.pwdType_password1 = 'password'
        this.pwdType_password2 = 'password'
      }
    }
  },
  methods: {
    px2rem,
    changePwdType(refName) {
      this['pwdType_' + refName] = this['pwdType_' + refName] == 'text' ? 'password' : 'text'
      this.$nextTick(() => {
        this.$refs[refName].focus()
      })
    },
    awscinit() {
      return new Promise((resolve, reject) => {
        let vm = this
        vm.nvc?.reset && vm.nvc.reset()
        AWSC.use('nvc', function(state, module) {
          // 初始化 调用module.init进行初始化
          vm.nvc = module.init({
            // 应用类型标识。它和使用场景标识（scene字段）一起决定了无痕验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
            appkey: 'FFFF0N0000000000B40A',
            //使用场景标识。它和应用类型标识（appkey字段）一起决定了无痕验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的scene值，请务必正确填写。
            scene: 'nvc_register',
            hideErrorCode: true,
            // 二次验证获取人机信息串，跟随业务请求一起上传至业务服务器，由业务服务器进行验签。
            success: function(data) {
              vm.ncvisible = false
              vm.nvcVal = data
              vm.submitForm()
            },
            // 前端二次验证失败时触发该回调参数
            fail: function(failCode) {
              vm.registerLoading = false
              console.log('failCode', failCode)
            },
            // 前端二次验证加载异常时触发该回调参数。
            error: function(errorCode) {
              vm.registerLoading = false
              console.log('errorCode', errorCode)
            }
          })
          resolve()
        })
      })
    },
    // 处理业务返回结果：人机信息串上传接口的回调函数，通过业务服务端的返回结果，控制无痕验证的不同状态。
    yourRegisterRequest(res) {
      let that = this
      // 业务服务器请求回调控制是否需要二次验证
      if (res.code === 1) {
        const data = {
          email: that.ruleForm1.email,
          password: that.ruleForm1.password
        }
        this.$login(data).then(res => {
          this.registerLoading = false
          if (res.code === 1) {
            that.$notify({
              type: 'success',
              title: 'Success',
              customClass: 'notify-success',
              duration: 5000,
              message: 'Login successfully'
            })
            const auth = res.data.token
            const isAdd = res.data.address_id
            that.$store.commit('msg/setTeamName', res.data.team)
            that.$store.commit('setAuth', auth) // 存储在vuex中用来进行客户端渲染
            localStorage.setItem('emailValidated', res.data.email_validated)
            localStorage.setItem('accessDomain', res.data.accessDomain)
            localStorage.setItem('regTime', res.data.reg_time) // 注册时间
            const expires = new Date(new Date() * 1 + 86400000 * 30) // 有效期一个月
            Cookie.set('auth', auth, { expires: expires }) // 在cookie中保存token用来进行服务器端渲染
            Cookie.set('isadd', isAdd, { expires: expires })
            const checkQuote = res.data.check_quote_status
            that.$store.commit('checkQuote', checkQuote)
            Cookie.set('checkQuote', checkQuote, { expires: expires })
            that.getMsg()
            that.$getUser().then(res => {
              if (res.code === 1) {
                localStorage.setItem('userId', res.data.id)
                localStorage.setItem('mail', res.data.email)
                localStorage.setItem('email', res.data.email)
                localStorage.setItem('userName', res.data.userName)
                localStorage.setItem('telephone', res.data.telephone)
                that.$store.commit('setAvatar', res.data.imageUrl)
                that.$store.commit('msg/setUserName', res.data.userName)
                that.$store.commit('msg/setEmail', res.data.email)
                that.$store.commit('msg/setFirstName', res.data.firstName)
                that.$store.commit('msg/setLastName', res.data.lastName)
                that.$store.dispatch('msg/getUrl')
                that.joinCreateTeam()
                that.$store.commit('openRegister', false)
                const loginType = that.$store.state.loginType
                that.$store.commit('loginOk', loginType) // 登录成功返回状态
                Cookie.set('avatar', res.data.imageUrl, {
                  expires: expires,
                  domain: that.$store.state.msg.url
                })
                Cookie.set('userName', res.data.userName, {
                  expires: expires,
                  domain: that.$store.state.msg.url
                })
                //插件引导 0需要显示引导 1不显示
                let VisibleSolidWorksGuideObj = JSON.parse(
                  localStorage.getItem('VisibleSolidWorksGuideObj')
                )
                //对象无数据是登录加入数据 置为0需要显示引导 1不显示
                if (!VisibleSolidWorksGuideObj || VisibleSolidWorksGuideObj == 'null') {
                  const obj = {}
                  obj[localStorage.getItem('mail')] = 0
                  VisibleSolidWorksGuideObj = obj
                }
                //判断对象有数据时中是否存在这个邮箱
                if (!VisibleSolidWorksGuideObj.hasOwnProperty(localStorage.getItem('mail'))) {
                  VisibleSolidWorksGuideObj[localStorage.getItem('mail')] = 0
                }
                localStorage.setItem(
                  'VisibleSolidWorksGuideObj',
                  JSON.stringify(VisibleSolidWorksGuideObj)
                )
              }else{
                that.$notify.error({
                  title: 'Error',
                  customClass: 'notify-error',
                  message: 'Incorrect user name or password'
                })
              }
            })
          } else {
            that.$notify.error({
              title: 'Error',
              customClass: 'notify-error',
              message: 'Incorrect user name or password'
            })
          }
        })
        window.setLayer({
          event: 'RDRegisterSuccess',
          dimension1: 'login',
          email: that.ruleForm1.email
        })
        // 无痕验证通过
      } else if (res.code === -995 && Number(res.data.BizCode) === 400) {
        this.registerLoading = false
        that.$notify.error({
          title: 'Error',
          customClass: 'notify-error',
          message: 'Please try again later'
        })
        return
        // 无痕验证失败，直接拦截
        this.ncvisible = true
        if (!this.$refs.ncRegister.children.length) {
          const ncoption = {
            // 声明滑动验证需要渲染的目标ID。
            renderTo: 'ncRegister',
            upLang: {
              cn: {
                //加载状态提示。
                LOADING: 'Loading...',
                //等待滑动状态提示。
                SLIDE: 'Please slide to verify',
                //验证通过状态提示。
                SUCCESS: 'Verified',
                //验证失败触发拦截状态提示。
                ERROR: 'Network error',
                //验证失败触发拦截状态提示。
                FAIL: 'Verification failed,please try again.'
              }
            }
          }
          // 唤醒二次验证（滑动验证码）
          that.nvc.getNC(ncoption)
        } else {
          // 重置（滑动验证码）
          that.nvc.reset()
        }
      } else {
        this.registerLoading = false
        this.$notify.error({
          title: 'Error',
          customClass: 'notify-error',
          message: res.msg
        })
      }
    },
    async submitForm() {
      // 获取UTC时差
      let offset = new Date().getTimezoneOffset() / -60
      if (isAtDSTime()) {
        offset -= 1
      }
      const data = {
        accounts: this.ruleForm1.email,
        password: this.ruleForm1.password,
        type: 'e',
        code: '',
        user: this.ruleForm1.firstName,
        userName: this.ruleForm1.lastName,
        nvc_val: this.nvcVal,
        invite_code: Cookie.get('rd_salesrep') ? Cookie.get('rd_salesrep') : null,
        time_zone: offset ? offset : 100
      }
      this.url.setField(data)
      this.clearRegisterTime()
      const res = await this.$register(data)
      this.yourRegisterRequest(res)
    },

    submitValid(formName) {
      let vm = this
      if (this.registerLoading) {
        return
      }
      this.registerLoading = true
      // 无论成功还是失败 3秒后取消loading
      this.registerTime = setTimeout(() => {
        vm.registerLoading = false
        vm.$notify.error({
          title: 'Error',
          customClass: 'notify-error',
          message: 'Please try again later'
        })
      }, 3000)
      vm.$refs[formName].validate(valid => {
        if (!valid) {
          vm.registerLoading = false
          vm.clearRegisterTime()
          return false
        }

        // 暂时关闭人机验证
        vm.submitForm()
        // if(!vm.nvc){
        //   vm.awscinit().then(() => {
        //     vm.nvc.getNVCValAsync(function(nvcVal) {
        //       vm.nvcVal = nvcVal
        //       vm.submitForm()
        //     })
        //   })
        // }else{
        //   vm.nvc.getNVCValAsync(function(nvcVal) {
        //     vm.nvcVal = nvcVal
        //     vm.submitForm()
        //    })
        // }
      })
    },
    close() {
      this.$store.commit('openRegister', false)
      if (
        !this.$store.state.auth &&
        !this.$store.state.indexRouters.includes(this.$route.path) &&
        !this.$store.state.dialogLogin
      ) {
        this.$router.push('/')
      }
    },
    login() {
      this.$store.commit('openRegister', false)
      this.$store.commit('openLogin', true)
    },
    getMsg() {
      const vm = this.tableShow
      const data = {
        size: vm.pageSize,
        page: vm.currentPage,
        status: -10
      }
      this.$getMsg(data).then(res => {
        this.$store.commit('msg/setUnreadNum', res.data.unread)
      })
    },
    clearRegisterTime() {
      if (this.registerTime) {
        clearInterval(this.registerTime)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.el-form-item__error {
  position: relative;
}
.selectItem .el-select-dropdown__empty {
  display: none;
}
.selectItem .el-select-dropdown__item {
  color: #252525;
}
.selectItem input::-webkit-input-placeholder {
  color: #878787;
}
.selectItem input::-moz-input-placeholder {
  color: #878787;
}
.selectItem input::-ms-input-placeholder {
  color: #878787;
}
.selectItem .el-select-group .el-select-dropdown__item {
  padding-left: 15px !important;
}
.phoneSelect .el-select-dropdown__item,
.phoneSelect .selected {
  color: #252525 !important;
  font-weight: 500;
}
.phoneSelect .countryTel {
  display: inline-block;
  width: 38px;
  margin-right: 8px;
}
.address-win /deep/ .el-dialog__wrapper .el-dialog {
  width: 1196px;
  height: 720px;
  font-weight: 400;
  font-family: Poppins;
  font-style: normal;
}
.noData {
  padding: 10px 0;
  color: #999;
  text-align: center;
  line-height: 22px;
}
.traceless-verifiy {
  box-sizing: border-box;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%);
  width: 400px;
  background-color: #fff;
  /deep/ .nc_1_nocaptcha {
    display: flex;
    justify-content: center;
  }
}
.dialog {
  width: 100%;
  opacity: 0.4;
  background: $c1B1C21;
  z-index: 1012;
}
.dialog-win::-webkit-scrollbar {
  width: 0 !important;
}
.dialog-win {
  min-width: 1100px;
  background: #ffffff;
  box-sizing: border-box;
  overflow: auto;
  z-index: 1013;
  display: flex;
  align-items: center;
}
.dialog-close {
  display: inline-block;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 26px;
  text-align: center;
  color: #000000;
  line-height: 26px;
  cursor: pointer;
}
.address-win /deep/ .el-dialog__header,
.address-win /deep/ .el-dialog__body {
  border: none;
  padding: 0;
}
.dialog-left {
  width: 576px;
  height: 720px;
}
.dialog-right {
  width: 620px;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-content {
    width: 500px;
    margin: 0 60px;
    .login-title {
      text-align: center;
      background: $white;
      font-size: 28px;
      font-weight: 600;
      line-height: 36px;
      color: #252525;
      margin: 0px 0 40px;
    }
  }
}

.demo-ruleForm {
  background: #fff;
  /deep/ input:-webkit-autofill {
    -webkit-text-fill-color: #252525;
  }
  /deep/ input::-webkit-input-placeholder {
    color: #878787;
  }
  /deep/ input::-moz-input-placeholder {
    color: #878787;
  }
  /deep/ input::-ms-input-placeholder {
    color: #878787;
  }
  /deep/ .el-form-item.is-error .el-input__inner {
    border-color: #ea543f;
  }
  /deep/ .el-form-item__content {
    line-height: normal;
  }
  /deep/ .el-form-item__error {
    padding-top: 8px;
    color: #ea543f;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
  }
}
.demo-ruleForm /deep/ .el-form-item__error {
  position: relative;
  top: 0;
  padding-top: 8px;
  color: #ea543f;
}
.passwordHint {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  line-height: 22px;
  color: #878787;
  margin-top: -12px;
  .passHint {
    display: flex;
    align-items: center;
    margin-right: 15px;
    font-weight: 400;
  }
  .passHint-g {
    color: #00a870;
  }
  .passHint-r {
    color: #ea543f;
  }
  .passHint-txt {
    margin-left: 6px;
    font-weight: 400;
  }
  .redioSpan {
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 1px solid #d0d0d0;
    border-radius: 50%;
    margin-right: 6px;
    box-sizing: border-box;
  }
}
.mart16 {
  margin-top: -16px;
}
hr {
  margin: 0.6rem 0;
  border-top: 0.02rem dashed #dce0e2;
}
.el-form /deep/ .el-form-item__label {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #252525;
  margin-bottom: 8px;
  padding: 0;
  &:after {
    content: '*';
    color: #f56c6c;
    margin-left: 0.02083rem;
  }
}
.companyItem {
  /deep/ .el-form-item__label {
    &:after {
      display: none;
    }
  }
}
.el-form /deep/ .el-select {
  width: 100%;
}
.el-form /deep/ .verify .el-input {
  width: 65%;
}
.el-form /deep/ .verify .el-button {
  width: 34%;
  overflow: hidden;
  padding: 0 0;
  border-width: 2px;
  height: 40px;
  font-size: 16px;
  color: #1283bc;
  background: #f8fdff;
  border-color: #ea543f;
}
.el-form /deep/ .verify .el-button:hover {
  width: 34%;
  overflow: hidden;
  padding: 0 0;
  border-width: 2px;
  height: 40px;
  font-size: 16px;
  color: #fff;
  background: #1283bc;
  border-color: #ea543f;
}
.el-form /deep/ .verify .el-button--primary.is-plain.is-disabled {
  color: #8cc5ff;
  background-color: #ecf5ff;
  border-color: #d9ecff;
}
.el-form /deep/ .el-form-item {
  margin-bottom: 20px;
}
.flagImg {
  position: absolute;
  left: 12px;
  top: 8px;
  z-index: 99;
}
.el-form /deep/ .el-input__inner {
  height: 38px;
  line-height: 38px;
  border: 1px solid #d0d0d0;
  color: #252525 !important;
}
.input-with-select {
  position: relative;
  /deep/ .el-input-group__prepend {
    box-sizing: border-box;
    padding: 0;
    height: 38px;
    width: 130px;
    background: #fff;
    border: 0;
    .el-select {
      margin: 0;
      height: 38px;
      .el-input__inner {
        border: 1px solid #dcdfe6;
        padding: 0 12px 0 46px;
        border-radius: 0;
        height: 38px;
      }
    }
  }
}
.optionDiv {
  width: 100%;
  margin-left: -28px;
  .optionImg {
    display: inline-block;
    margin-right: 12px;
  }
}
.otherOptionDiv {
  display: flex;
  .otherMat {
    flex: 1;
    /deep/ .el-input__inner {
      height: 38px;
      line-height: 38px;
      margin-right: 12px;
    }
  }
  /deep/ .el-button {
    font-size: 14px;
    line-height: 38px;
    padding: 0 18px;
    height: 38px;
    margin-left: 12px;
  }
}
.registerBtn {
  text-align: center;
  padding: 0 40px 0;
  background: #fff;
  margin-top: 40px;
  .el-button {
    padding: 8px 64px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    border: 0 !important;
  }
  /deep/ .sm-pop-inner {
    position: relative;
    height: 50px;
    .nc_1_nocaptcha {
      height: 50px;
      .nc_wrapper,
      .nc_scale {
        width: 100% !important;
        height: 50px !important;
        line-height: 50px !important;
        .btn_slide {
          width: 60px;
          height: 48px !important;
          line-height: 50px !important;
        }
        .btn_ok {
          left: auto;
          height: 48px;
          line-height: 48px;
          right: 0px;
        }
        .nc-lang-cnt {
          height: 50px !important;
          line-height: 50px !important;
        }
      }
      .nc_wrapper {
        .errloading {
          background-color: #fff;
          height: 50px !important;
          line-height: 50px !important;
          padding: 0 10px;
          .icon_warn {
            font-size: 18px;
          }
        }
      }
    }
  }
}
.login_in {
  width: 100%;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  background: #ffffff;
  padding: 16px 0 0;
  color: #878787;
  .loginTxt {
    color: #ea543f;
    text-decoration: underline;
    cursor: pointer;
  }
}
.agreement {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 22px;
  color: #252525;
  /deep/ .el-checkbox__inner {
    width: 18px;
    height: 18px;
    border-radius: 3px;
    border-color: $cC8CEDA;
  }
  .agreeLink {
    color: #ea543f;
    text-decoration-line: underline;
    cursor: pointer;
  }
  /deep/ .is-checked {
    .el-checkbox__inner {
      background-color: $primary;
      border-color: $primary;
      &::after {
        left: 7px;
        top: 4px;
      }
    }
  }
  /deep/ .el-checkbox__label {
    line-height: 22px;
    color: #252525;
  }
}
.showPassWord {
  line-height: 38px;
  padding: 0 3px;
  width: 18px;
  height: 20px;
  cursor: pointer;
  .eyeIcon {
    width: 18px;
    height: 20px;
  }
}
</style>
<style lang="scss">
.tooltip_Additional {
  .tip {
    line-height: 20px;
    color: #fff;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }
}
.tipsIcon {
  line-height: 24px !important;
  margin-left: 5px;
}
</style>
