var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "aside",
    class: [_vm.openAside ? 'aside-open' : 'aside-close']
  }, [_c('div', {
    staticClass: "aside-cont"
  }, [_c('div', {
    staticClass: "aside-cont-items"
  }, [_vm._l(_vm.routerData, function (item, index) {
    return _c('div', {
      key: 'route-item-' + index
    }, [item.type == 'personal' ? _c('div', {
      staticClass: "is-line"
    }) : _vm._e(), _vm._v(" "), _c('div', {
      staticClass: "aside-cont-item",
      class: item.active ? 'active' : '',
      on: {
        "click": function ($event) {
          return _vm.changeRouter(item.path);
        }
      }
    }, [_c('div', {
      staticClass: "align-center aside-cont-item-c",
      attrs: {
        "id": item.type == 'createTeam' ? 'home-guide-step4' : ''
      }
    }, [_vm.openAside ? _c('div', [item.icon ? _c('div', {
      staticClass: "aside-btn-link icon"
    }, [_c('svg', {
      staticClass: "svg",
      attrs: {
        "aria-hidden": "true"
      }
    }, [_c('use', {
      attrs: {
        "xlink:href": item.icon
      }
    })])]) : item.iconSvg ? _c('div', {
      staticClass: "aside-btn-link icon"
    }, [_c('img', {
      staticClass: "svg",
      attrs: {
        "src": item.iconSvg,
        "alt": ""
      }
    })]) : _c('div', {
      staticClass: "aside-btn-link icon img"
    }, [_c('span', {
      class: item.elIcon
    })])]) : _c('div', {
      class: ['aside-btn-link icon', item.elIcon ? 'img' : '']
    }, [item.icon ? _c('el-tooltip', {
      attrs: {
        "effect": "dark",
        "content": item.title,
        "placement": "right"
      }
    }, [_c('svg', {
      staticClass: "svg",
      attrs: {
        "aria-hidden": "true"
      }
    }, [_c('use', {
      attrs: {
        "xlink:href": item.icon
      }
    })])]) : item.iconSvg ? _c('el-tooltip', {
      attrs: {
        "effect": "dark",
        "content": item.title,
        "placement": "right"
      }
    }, [_c('img', {
      staticClass: "svg",
      attrs: {
        "src": item.iconSvg,
        "alt": ""
      }
    })]) : _c('el-tooltip', {
      attrs: {
        "effect": "dark",
        "content": item.title,
        "placement": "right"
      }
    }, [_c('span', {
      class: item.elIcon
    })])], 1), _vm._v(" "), _vm.openAside ? _c('span', {
      staticClass: "text align-center"
    }, [_vm._v(_vm._s(item.title) + " "), item.type == 'personal' && _vm.newTime ? _c('span', {
      staticClass: "new-icon"
    }, [_vm._v("new")]) : _vm._e()]) : _vm._e()])])]);
  }), _vm._v(" "), _c('div', {
    staticClass: "team-menu-box"
  }, _vm._l(_vm.teamListData, function (item) {
    return _c('div', {
      key: 'team-menu-' + item.id
    }, [_c('div', {
      staticClass: "aside-cont-item",
      class: item.id == _vm.teamActive ? 'active' : '',
      on: {
        "click": function ($event) {
          return _vm.changeTeam(item.id);
        }
      }
    }, [_c('div', {
      staticClass: "align-center aside-cont-item-c"
    }, [_vm.openAside ? _c('div', [_c('div', {
      staticClass: "aside-btn-link teamN"
    }, [_c('span', [_vm._v(_vm._s(item.team_name && item.team_name.slice(0, 1).toUpperCase()))])])]) : _c('div', [_c('el-tooltip', {
      attrs: {
        "effect": "dark",
        "content": item.team_name,
        "placement": "right"
      }
    }, [_c('div', {
      staticClass: "aside-btn-link teamN"
    }, [_c('span', [_vm._v(_vm._s(item.team_name && item.team_name.slice(0, 1).toUpperCase()))])])])], 1), _vm._v(" "), _vm.openAside ? _c('span', {
      staticClass: "text"
    }, [_vm._v(_vm._s(item.team_name))]) : _vm._e()])])]);
  }), 0)], 2)]), _vm._v(" "), _c('div', {
    class: ['aside-info-open aside-cont-open', _vm.openAside ? '' : 'open-show'],
    on: {
      "click": _vm.clickAside
    }
  }, [_vm.openAside ? _c('svg-icon', {
    attrs: {
      "width": _vm.px2rem('24px'),
      "height": _vm.px2rem('24px'),
      "icon-class": "hide"
    }
  }) : _c('svg-icon', {
    attrs: {
      "width": _vm.px2rem('24px'),
      "height": _vm.px2rem('24px'),
      "icon-class": "show"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }