import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a1417246 = () => interopDefault(import('../pages/a.vue' /* webpackChunkName: "pages/a" */))
const _6949a300 = () => interopDefault(import('../pages/connorsTeam/index.vue' /* webpackChunkName: "pages/connorsTeam/index" */))
const _767ac3c4 = () => interopDefault(import('../pages/mailVerify.vue' /* webpackChunkName: "pages/mailVerify" */))
const _3f309750 = () => interopDefault(import('../pages/molds/index.vue' /* webpackChunkName: "pages/molds/index" */))
const _bbb62c80 = () => interopDefault(import('../pages/msg/index.vue' /* webpackChunkName: "pages/msg/index" */))
const _386ca44d = () => interopDefault(import('../pages/order/index.vue' /* webpackChunkName: "pages/order/index" */))
const _438e13f4 = () => interopDefault(import('../pages/personal.vue' /* webpackChunkName: "pages/personal" */))
const _95ac8c0a = () => interopDefault(import('../pages/quote/index.vue' /* webpackChunkName: "pages/quote/index" */))
const _4e60a746 = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _fa4dec02 = () => interopDefault(import('../pages/user.vue' /* webpackChunkName: "pages/user" */))
const _bb74344a = () => interopDefault(import('../pages/user/password.vue' /* webpackChunkName: "pages/user/password" */))
const _19274cfe = () => interopDefault(import('../pages/connorsTeam/newQuote.vue' /* webpackChunkName: "pages/connorsTeam/newQuote" */))
const _4f8cf70d = () => interopDefault(import('../pages/enterprise/register.vue' /* webpackChunkName: "pages/enterprise/register" */))
const _3b944b7a = () => interopDefault(import('../pages/enterprise/rfq.vue' /* webpackChunkName: "pages/enterprise/rfq" */))
const _754a9d1d = () => interopDefault(import('../pages/help-center/specifications-policy.vue' /* webpackChunkName: "pages/help-center/specifications-policy" */))
const _10a52d39 = () => interopDefault(import('../pages/landing-page/cnc-machining.vue' /* webpackChunkName: "pages/landing-page/cnc-machining" */))
const _79cf355e = () => interopDefault(import('../pages/landing-page/competitors.vue' /* webpackChunkName: "pages/landing-page/competitors" */))
const _f1e32fee = () => interopDefault(import('../pages/member/agreement.vue' /* webpackChunkName: "pages/member/agreement" */))
const _e33f0376 = () => interopDefault(import('../pages/member/findPassword.vue' /* webpackChunkName: "pages/member/findPassword" */))
const _5f855478 = () => interopDefault(import('../pages/member/joinTeam.vue' /* webpackChunkName: "pages/member/joinTeam" */))
const _f7dced30 = () => interopDefault(import('../pages/member/login.vue' /* webpackChunkName: "pages/member/login" */))
const _10063847 = () => interopDefault(import('../pages/member/privacy.vue' /* webpackChunkName: "pages/member/privacy" */))
const _99d39318 = () => interopDefault(import('../pages/member/register.vue' /* webpackChunkName: "pages/member/register" */))
const _f92501b0 = () => interopDefault(import('../pages/member/registerBak.vue' /* webpackChunkName: "pages/member/registerBak" */))
const _1c128789 = () => interopDefault(import('../pages/member/resetPassword.vue' /* webpackChunkName: "pages/member/resetPassword" */))
const _ca24d08a = () => interopDefault(import('../pages/member/thank-you.vue' /* webpackChunkName: "pages/member/thank-you" */))
const _6d7af4e6 = () => interopDefault(import('../pages/order/detail.vue' /* webpackChunkName: "pages/order/detail" */))
const _295b8c41 = () => interopDefault(import('../pages/order/orderSubmit.vue' /* webpackChunkName: "pages/order/orderSubmit" */))
const _1820589f = () => interopDefault(import('../pages/order/paybank.vue' /* webpackChunkName: "pages/order/paybank" */))
const _62e506c1 = () => interopDefault(import('../pages/order/payment.vue' /* webpackChunkName: "pages/order/payment" */))
const _4c2fded6 = () => interopDefault(import('../pages/order/paystatus.vue' /* webpackChunkName: "pages/order/paystatus" */))
const _051a6eb0 = () => interopDefault(import('../pages/order/print.vue' /* webpackChunkName: "pages/order/print" */))
const _6851553a = () => interopDefault(import('../pages/order/utils.js' /* webpackChunkName: "pages/order/utils" */))
const _477a117d = () => interopDefault(import('../pages/quote/blank.vue' /* webpackChunkName: "pages/quote/blank" */))
const _6927dfad = () => interopDefault(import('../pages/quote/checkout.vue' /* webpackChunkName: "pages/quote/checkout" */))
const _14a91f4f = () => interopDefault(import('../pages/quote/checkoutBak.vue' /* webpackChunkName: "pages/quote/checkoutBak" */))
const _ef3ed610 = () => interopDefault(import('../pages/quote/detail.vue' /* webpackChunkName: "pages/quote/detail" */))
const _2dd35226 = () => interopDefault(import('../pages/quote/detail3.0.vue' /* webpackChunkName: "pages/quote/detail3.0" */))
const _6f429d8a = () => interopDefault(import('../pages/quote/manualInfo.vue' /* webpackChunkName: "pages/quote/manualInfo" */))
const _193378a3 = () => interopDefault(import('../pages/quote/manualNew.vue' /* webpackChunkName: "pages/quote/manualNew" */))
const _3bfdeb46 = () => interopDefault(import('../pages/quote/quoteIndex.vue' /* webpackChunkName: "pages/quote/quoteIndex" */))
const _0ea9b1e3 = () => interopDefault(import('../pages/quote/quoteList.vue' /* webpackChunkName: "pages/quote/quoteList" */))
const _7bdb1a40 = () => interopDefault(import('../pages/quote/reload.vue' /* webpackChunkName: "pages/quote/reload" */))
const _4510bcf8 = () => interopDefault(import('../pages/quote/request.vue' /* webpackChunkName: "pages/quote/request" */))
const _2219e6b4 = () => interopDefault(import('../pages/quote/rfq.vue' /* webpackChunkName: "pages/quote/rfq" */))
const _0e61276a = () => interopDefault(import('../pages/quote/rfq-2.vue' /* webpackChunkName: "pages/quote/rfq-2" */))
const _4c7c05d1 = () => interopDefault(import('../pages/quote/selectCouponDialog.vue' /* webpackChunkName: "pages/quote/selectCouponDialog" */))
const _66580826 = () => interopDefault(import('../pages/connorsTeam/components/coupon.vue' /* webpackChunkName: "pages/connorsTeam/components/coupon" */))
const _50f4952c = () => interopDefault(import('../pages/connorsTeam/components/donateIntegralDialog.vue' /* webpackChunkName: "pages/connorsTeam/components/donateIntegralDialog" */))
const _f620d216 = () => interopDefault(import('../pages/connorsTeam/components/inviteTeamDialog.vue' /* webpackChunkName: "pages/connorsTeam/components/inviteTeamDialog" */))
const _2569e607 = () => interopDefault(import('../pages/connorsTeam/components/levelDescriptionDialog.vue' /* webpackChunkName: "pages/connorsTeam/components/levelDescriptionDialog" */))
const _10648b15 = () => interopDefault(import('../pages/connorsTeam/components/orderList.vue' /* webpackChunkName: "pages/connorsTeam/components/orderList" */))
const _60e51942 = () => interopDefault(import('../pages/connorsTeam/components/pointshistoryDialog.vue' /* webpackChunkName: "pages/connorsTeam/components/pointshistoryDialog" */))
const _33273443 = () => interopDefault(import('../pages/connorsTeam/components/quoteList.vue' /* webpackChunkName: "pages/connorsTeam/components/quoteList" */))
const _46b9e5c0 = () => interopDefault(import('../pages/molds/C/moldsSkeleton.vue' /* webpackChunkName: "pages/molds/C/moldsSkeleton" */))
const _3a87a7e2 = () => interopDefault(import('../pages/molds/C/selectProdMoldsDilog.vue' /* webpackChunkName: "pages/molds/C/selectProdMoldsDilog" */))
const _f027b2fe = () => interopDefault(import('../pages/molds/C/uploadMolding.vue' /* webpackChunkName: "pages/molds/C/uploadMolding" */))
const _5b959339 = () => interopDefault(import('../pages/order/components/AddOrderToTeam.vue' /* webpackChunkName: "pages/order/components/AddOrderToTeam" */))
const _fab9df70 = () => interopDefault(import('../pages/order/components/Bills.vue' /* webpackChunkName: "pages/order/components/Bills" */))
const _13657e17 = () => interopDefault(import('../pages/order/components/checkDfm.vue' /* webpackChunkName: "pages/order/components/checkDfm" */))
const _2e666115 = () => interopDefault(import('../pages/order/components/checkModel.vue' /* webpackChunkName: "pages/order/components/checkModel" */))
const _1278ad78 = () => interopDefault(import('../pages/order/components/confirmAddonPay.vue' /* webpackChunkName: "pages/order/components/confirmAddonPay" */))
const _0e230a90 = () => interopDefault(import('../pages/order/components/confirmBalance.vue' /* webpackChunkName: "pages/order/components/confirmBalance" */))
const _455e745c = () => interopDefault(import('../pages/order/components/confirmPay.vue' /* webpackChunkName: "pages/order/components/confirmPay" */))
const _ef6ccf80 = () => interopDefault(import('../pages/order/components/confirmPayBak.vue' /* webpackChunkName: "pages/order/components/confirmPayBak" */))
const _1a6738a6 = () => interopDefault(import('../pages/order/components/confirmSample.vue' /* webpackChunkName: "pages/order/components/confirmSample" */))
const _7fb40ccd = () => interopDefault(import('../pages/order/components/countdown.vue' /* webpackChunkName: "pages/order/components/countdown" */))
const _8a272446 = () => interopDefault(import('../pages/order/components/LogisticsInfo.vue' /* webpackChunkName: "pages/order/components/LogisticsInfo" */))
const _4d1467e0 = () => interopDefault(import('../pages/order/components/orderActive.vue' /* webpackChunkName: "pages/order/components/orderActive" */))
const _a1dad2e6 = () => interopDefault(import('../pages/order/components/orderSteps.vue' /* webpackChunkName: "pages/order/components/orderSteps" */))
const _5b7bebc2 = () => interopDefault(import('../pages/order/components/orderTips.vue' /* webpackChunkName: "pages/order/components/orderTips" */))
const _92912426 = () => interopDefault(import('../pages/order/components/RemoveOrderFromTeam.vue' /* webpackChunkName: "pages/order/components/RemoveOrderFromTeam" */))
const _6fc67495 = () => interopDefault(import('../pages/order/components/Shipments.vue' /* webpackChunkName: "pages/order/components/Shipments" */))
const _799d3e1c = () => interopDefault(import('../pages/order/components/updatePO.vue' /* webpackChunkName: "pages/order/components/updatePO" */))
const _75085d8a = () => interopDefault(import('../pages/order/components/view3d.vue' /* webpackChunkName: "pages/order/components/view3d" */))
const _732751d2 = () => interopDefault(import('../pages/order/mixins/ImporComponents.js' /* webpackChunkName: "pages/order/mixins/ImporComponents" */))
const _116f0245 = () => interopDefault(import('../pages/quote/quoteIndex' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/a",
    component: _a1417246,
    name: "a"
  }, {
    path: "/connorsTeam",
    component: _6949a300,
    name: "connorsTeam"
  }, {
    path: "/mailVerify",
    component: _767ac3c4,
    name: "mailVerify"
  }, {
    path: "/molds",
    component: _3f309750,
    name: "molds"
  }, {
    path: "/msg",
    component: _bbb62c80,
    name: "msg"
  }, {
    path: "/order",
    component: _386ca44d,
    name: "order"
  }, {
    path: "/personal",
    component: _438e13f4,
    name: "personal"
  }, {
    path: "/quote",
    component: _95ac8c0a,
    name: "quote"
  }, {
    path: "/test",
    component: _4e60a746,
    name: "test"
  }, {
    path: "/user",
    component: _fa4dec02,
    name: "user",
    children: [{
      path: "password",
      component: _bb74344a,
      name: "user-password"
    }]
  }, {
    path: "/connorsTeam/newQuote",
    component: _19274cfe,
    name: "connorsTeam-newQuote"
  }, {
    path: "/enterprise/register",
    component: _4f8cf70d,
    name: "enterprise-register"
  }, {
    path: "/enterprise/rfq",
    component: _3b944b7a,
    name: "enterprise-rfq"
  }, {
    path: "/help-center/specifications-policy",
    component: _754a9d1d,
    name: "help-center-specifications-policy"
  }, {
    path: "/landing-page/cnc-machining",
    component: _10a52d39,
    name: "landing-page-cnc-machining"
  }, {
    path: "/landing-page/competitors",
    component: _79cf355e,
    name: "landing-page-competitors"
  }, {
    path: "/member/agreement",
    component: _f1e32fee,
    name: "member-agreement"
  }, {
    path: "/member/findPassword",
    component: _e33f0376,
    name: "member-findPassword"
  }, {
    path: "/member/joinTeam",
    component: _5f855478,
    name: "member-joinTeam"
  }, {
    path: "/member/login",
    component: _f7dced30,
    name: "member-login"
  }, {
    path: "/member/privacy",
    component: _10063847,
    name: "member-privacy"
  }, {
    path: "/member/register",
    component: _99d39318,
    name: "member-register"
  }, {
    path: "/member/registerBak",
    component: _f92501b0,
    name: "member-registerBak"
  }, {
    path: "/member/resetPassword",
    component: _1c128789,
    name: "member-resetPassword"
  }, {
    path: "/member/thank-you",
    component: _ca24d08a,
    name: "member-thank-you"
  }, {
    path: "/order/detail",
    component: _6d7af4e6,
    name: "order-detail"
  }, {
    path: "/order/orderSubmit",
    component: _295b8c41,
    name: "order-orderSubmit"
  }, {
    path: "/order/paybank",
    component: _1820589f,
    name: "order-paybank"
  }, {
    path: "/order/payment",
    component: _62e506c1,
    name: "order-payment"
  }, {
    path: "/order/paystatus",
    component: _4c2fded6,
    name: "order-paystatus"
  }, {
    path: "/order/print",
    component: _051a6eb0,
    name: "order-print"
  }, {
    path: "/order/utils",
    component: _6851553a,
    name: "order-utils"
  }, {
    path: "/quote/blank",
    component: _477a117d,
    name: "quote-blank"
  }, {
    path: "/quote/checkout",
    component: _6927dfad,
    name: "quote-checkout"
  }, {
    path: "/quote/checkoutBak",
    component: _14a91f4f,
    name: "quote-checkoutBak"
  }, {
    path: "/quote/detail",
    component: _ef3ed610,
    name: "quote-detail"
  }, {
    path: "/quote/detail3.0",
    component: _2dd35226,
    name: "quote-detail3.0"
  }, {
    path: "/quote/manualInfo",
    component: _6f429d8a,
    name: "quote-manualInfo"
  }, {
    path: "/quote/manualNew",
    component: _193378a3,
    name: "quote-manualNew"
  }, {
    path: "/quote/quoteIndex",
    component: _3bfdeb46,
    name: "quote-quoteIndex"
  }, {
    path: "/quote/quoteList",
    component: _0ea9b1e3,
    name: "quote-quoteList"
  }, {
    path: "/quote/reload",
    component: _7bdb1a40,
    name: "quote-reload"
  }, {
    path: "/quote/request",
    component: _4510bcf8,
    name: "quote-request"
  }, {
    path: "/quote/rfq",
    component: _2219e6b4,
    name: "quote-rfq"
  }, {
    path: "/quote/rfq-2",
    component: _0e61276a,
    name: "quote-rfq-2"
  }, {
    path: "/quote/selectCouponDialog",
    component: _4c7c05d1,
    name: "quote-selectCouponDialog"
  }, {
    path: "/connorsTeam/components/coupon",
    component: _66580826,
    name: "connorsTeam-components-coupon"
  }, {
    path: "/connorsTeam/components/donateIntegralDialog",
    component: _50f4952c,
    name: "connorsTeam-components-donateIntegralDialog"
  }, {
    path: "/connorsTeam/components/inviteTeamDialog",
    component: _f620d216,
    name: "connorsTeam-components-inviteTeamDialog"
  }, {
    path: "/connorsTeam/components/levelDescriptionDialog",
    component: _2569e607,
    name: "connorsTeam-components-levelDescriptionDialog"
  }, {
    path: "/connorsTeam/components/orderList",
    component: _10648b15,
    name: "connorsTeam-components-orderList"
  }, {
    path: "/connorsTeam/components/pointshistoryDialog",
    component: _60e51942,
    name: "connorsTeam-components-pointshistoryDialog"
  }, {
    path: "/connorsTeam/components/quoteList",
    component: _33273443,
    name: "connorsTeam-components-quoteList"
  }, {
    path: "/molds/C/moldsSkeleton",
    component: _46b9e5c0,
    name: "molds-C-moldsSkeleton"
  }, {
    path: "/molds/C/selectProdMoldsDilog",
    component: _3a87a7e2,
    name: "molds-C-selectProdMoldsDilog"
  }, {
    path: "/molds/C/uploadMolding",
    component: _f027b2fe,
    name: "molds-C-uploadMolding"
  }, {
    path: "/order/components/AddOrderToTeam",
    component: _5b959339,
    name: "order-components-AddOrderToTeam"
  }, {
    path: "/order/components/Bills",
    component: _fab9df70,
    name: "order-components-Bills"
  }, {
    path: "/order/components/checkDfm",
    component: _13657e17,
    name: "order-components-checkDfm"
  }, {
    path: "/order/components/checkModel",
    component: _2e666115,
    name: "order-components-checkModel"
  }, {
    path: "/order/components/confirmAddonPay",
    component: _1278ad78,
    name: "order-components-confirmAddonPay"
  }, {
    path: "/order/components/confirmBalance",
    component: _0e230a90,
    name: "order-components-confirmBalance"
  }, {
    path: "/order/components/confirmPay",
    component: _455e745c,
    name: "order-components-confirmPay"
  }, {
    path: "/order/components/confirmPayBak",
    component: _ef6ccf80,
    name: "order-components-confirmPayBak"
  }, {
    path: "/order/components/confirmSample",
    component: _1a6738a6,
    name: "order-components-confirmSample"
  }, {
    path: "/order/components/countdown",
    component: _7fb40ccd,
    name: "order-components-countdown"
  }, {
    path: "/order/components/LogisticsInfo",
    component: _8a272446,
    name: "order-components-LogisticsInfo"
  }, {
    path: "/order/components/orderActive",
    component: _4d1467e0,
    name: "order-components-orderActive"
  }, {
    path: "/order/components/orderSteps",
    component: _a1dad2e6,
    name: "order-components-orderSteps"
  }, {
    path: "/order/components/orderTips",
    component: _5b7bebc2,
    name: "order-components-orderTips"
  }, {
    path: "/order/components/RemoveOrderFromTeam",
    component: _92912426,
    name: "order-components-RemoveOrderFromTeam"
  }, {
    path: "/order/components/Shipments",
    component: _6fc67495,
    name: "order-components-Shipments"
  }, {
    path: "/order/components/updatePO",
    component: _799d3e1c,
    name: "order-components-updatePO"
  }, {
    path: "/order/components/view3d",
    component: _75085d8a,
    name: "order-components-view3d"
  }, {
    path: "/order/mixins/ImporComponents",
    component: _732751d2,
    name: "order-mixins-ImporComponents"
  }, {
    path: "/",
    component: _116f0245,
    name: "/"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
