import jsPDF from 'jspdf'
import logo from './logo-img.png'
import moldImg from './mould_default.png'
import baseBoldFont from './chinaFont/baseBoldFont'
import baseFont from './chinaFont/baseFont'
import fangzhengfangsong from './chinaFont/fangzhengfangsong-normal'
import heiti from './chinaFont/heiti-normal'
import { multilineText, format } from './utils'
class PI {
  constructor({ request, ossUrl, title }) {
    this.request = request
    this.ossUrl = ossUrl
    this.title = title ?? 'PROFORMA INVOICE'
    // 当前页面位置
    this.currentPage = 1
    // 总页面
    this.total = 1
    // 开头x轴
    this.baseX = 15
    // 记录零件列表中每一项x轴的位置
    this.partsTh = [this.baseX + 2, 46, 137, 150, 173]
    // 记录模具列表中每一项x轴的位置
    this.moldTh = [this.baseX + 2, 38, 68, 91, 120, 137, 150, 173]
    // 记录页面所在Y轴
    this.currentY = 0
    //
    this.maxPageY = 260
    this.doc = new jsPDF()
  }
  // 字符串分割指定长度的数组
  splitStr(str, length) {
    const arr = []

    let index = 0
    while (index < str.length) {
      arr.push(str.slice(index, (index += length)))
    }

    return arr
  }

  getImgCanvas(url, bg = '#ffffff') {
    return new Promise(resolve => {
      const img = new Image()
      img.src = url
      img.style.backgroundColor = bg

      img.onload = e => {
        const imgWidth = img.width
        const imgHeight = img.height

        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        canvas.width = imgWidth
        canvas.height = imgHeight
        ctx.fillStyle = bg
        ctx.fillRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(img, 0, 0, imgWidth, imgHeight)
        resolve({
          img: canvas.toDataURL('image/jpeg'),
          msg: {
            ratio: imgWidth / imgHeight,
            imgWidth,
            imgHeight
          }
        })
      }
    })
  }

  // 写顶部相关信息
  async writeTop() {
    try {
      const { img } = await this.getImgCanvas(logo)
      this.doc.addImage(img, this.baseX, 14, 40, 14, 'logo', 'NONE')
      this.doc.setTextColor(0, 0, 0)
      this.doc.setFont('BaseBoldFont')
      this.doc.setFontSize(16)
      this.doc.text(this.title, 191.5, 22, {
        align: 'right',
        charSpace: 0.4
      })
      this.doc.setFont('BaseFont')
      this.doc.setFontSize(8)
      this.doc.text(`# ${this.data.order_no}${this.data.order.batch_no?' - Batch' + this.data.order.batch_no:''}`, 195, 27, null, null, 'right')

      return Promise.resolve()
    } catch (error) {
      console.log(error)
    }
  }
  writeLeftMsg() {
    const baseX1 = this.baseX
    const baseX2 = 40
    let varY = 50
    this.doc.setFontSize(8)
    this.doc.setFont('BaseBoldFont')
    this.doc.text('To:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    const to = multilineText(
      this.doc,
      this.data.address.to||'',
      baseX2,
      varY,
      70,
      99
    )
    varY = to.y + 5

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Atten:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    this.doc.setTextColor(234, 84, 63)
    const atten = multilineText(
      this.doc,
      this.data.address.atten||'',
      baseX2,
      varY,
      70,
      99
    )
    varY = atten.y + 5
    // this.doc.text(this.data.address.atten, baseX2, varY, null, null, "left");
    // varY += 5

    this.doc.setTextColor(0, 0, 0)
    this.doc.setFont('BaseBoldFont')
    this.doc.text('E-mail:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    const email = multilineText(
      this.doc,
      this.data.address.email||'',
      baseX2,
      varY,
      70,
      99
    )
    varY = email.y + 5
    // this.doc.text(this.data.address.email, baseX2, varY, null, null, "left");
    // varY += 5

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Tel/Skype:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    this.doc.text(this.data.address.tel||'', baseX2, varY, null, null, 'left')
    varY += 5

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Ship To:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    const ship = multilineText(
      this.doc,
      this.data.address.ship_to||'',
      baseX2,
      varY,
      70,
      99
    )
    varY = ship.y
    varY += 5

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Invoice To:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    const invoice = multilineText(
      this.doc,
      this.data.address.invoice_to||'',
      baseX2,
      varY,
      70,
      99
    )
    varY = invoice.y
    return varY
  }
  writeRightMsg() {
    const baseX1 = 122
    const baseX2 = 150
    this.doc.setFontSize(8)
    let varY = 50

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Invoice No.:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    this.doc.text(
      this.data.order.batch_no?`${this.data.address.invoice_no} - Batch ${this.data.order.batch_no}`: this.data.address.invoice_no || '',
      baseX2,
      varY,
      null,
      null,
      'left'
    )
    varY += 5

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Invoice Date:', baseX1, varY, null, null, 'left')
    this.doc.setTextColor(234, 84, 63)
    this.doc.setFont('BaseFont')
    this.doc.text(
      this.data.address.invoice_date||'',
      baseX2,
      varY,
      null,
      null,
      'left'
    )
    varY += 5

    this.doc.setFont('BaseBoldFont')
    this.doc.setTextColor(0, 0, 0)
    this.doc.text('PO No.:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    const po = multilineText(
      this.doc,
      this.data.address.po_no||'',
      baseX2,
      varY,
      40,
      99
    )
    varY = po.y + 5

    this.doc.setFont('BaseBoldFont')
    this.doc.text('PO Date:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    this.doc.text(
      this.data.address.po_date||'',
      baseX2,
      varY,
      null,
      null,
      'left'
    )
    varY += 5

    if (this.data.order.process_type === 2) {
      this.doc.setFont('BaseBoldFont')
      this.doc.text(
        'Lead time (business day)',
        baseX1,
        varY,
        null,
        null,
        'left'
      )
      varY += 5
      this.doc.setFont('BaseFont')
      this.doc.text('Mold:', baseX1 + 2, varY, null, null, 'left')
      this.doc.text(
        `${this.data.order.mould_lead_time} days`,
        baseX2,
        varY,
        null,
        null,
        'left'
      )
      varY += 5

      this.doc.text('Part Production:', baseX1 + 2, varY, null, null, 'left')
      this.doc.text(
        `${this.data.order.lead_time} days`,
        baseX2,
        varY,
        null,
        null,
        'left'
      )
      varY += 5
    } else {
      this.doc.setFont('BaseBoldFont')
      this.doc.text('Lead Time:', baseX1, varY, null, null, 'left')
      this.doc.setFont('BaseFont')
      this.doc.text(
        `${this.data.order.lead_time} Business days`,
        baseX2,
        varY,
        null,
        null,
        'left'
      )
      varY += 5
    }

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Shipping:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    this.doc.text(this.data.address.shipping, baseX2, varY, null, null, 'left')
    varY += 5

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Validity:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    this.doc.text(
      `${this.data.address.validity}`,
      baseX2,
      varY,
      null,
      null,
      'left'
    )
    varY += 5

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Origin:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    this.doc.text('China', baseX2, varY, null, null, 'left')
    return varY
  }
  writeMoldTbHeader() {
    const baseY = this.currentY
    this.doc.setFontSize(7)
    this.doc.setFont('BaseBoldFont')
    this.doc.text('Mold', this.moldTh[0], baseY, null, null, 'left')
    this.doc.text('Mold No.', this.moldTh[1], baseY, null, null, 'left')
    this.doc.text('Mold Material', this.moldTh[2], baseY, null, null, 'center')
    this.doc.text('Mold life', this.moldTh[3], baseY, null, null, 'left')
    this.doc.text('Cavity', this.moldTh[4], baseY, null, null, 'center')
    this.doc.text('Qty', this.moldTh[5], baseY, null, null, 'center')
    this.doc.text('Unit Price', this.moldTh[6], baseY, null, null, 'left')
    this.doc.text('Price', this.moldTh[7], baseY, null, null, 'left')
  }
  writeBlockBg(y, h, cl) {
    let color = [250, 250, 250]
    if (cl) {
      var r = parseInt(cl.substring(1, 3), 16)
      var g = parseInt(cl.substring(3, 5), 16)
      var b = parseInt(cl.substring(5, 7), 16)
      color = [r, g, b]
    }
    this.doc.setDrawColor(255, 255, 255)
    this.doc.setFillColor(...color)
    this.doc.rect(this.baseX, y, 180, h > 18 ? h : 18, 'FD')
  }
  writePartTbHeader() {
    const baseY = this.currentY
    this.doc.setFontSize(7)
    this.doc.setFont('BaseBoldFont')
    this.doc.text('Item', this.partsTh[0], baseY, null, null, 'left')
    this.doc.text('Description', this.partsTh[1], baseY, null, null, 'left')
    this.doc.text('Qty', this.partsTh[2], baseY, null, null, 'center')
    this.doc.text('Unit Price', this.partsTh[3], baseY, null, null, 'left')
    this.doc.text('Price', this.partsTh[4], baseY, null, null, 'left')
  }
  async writePartBlockBg(index, part) {
    // 这里是要画背景矩形，但是由于不知道零件高度多少，所以逻辑是先画零件内容，得到高度后，再根据返回的零件数据的最高y得到矩形的高度
    let originY = this.currentY
    let maxY = 0
    const partRes = await this.writePart(
      index,
      part,
      this.currentY,
      index % 2 === 0 ? 'rgb(250, 250, 250)' : '#ffffff'
    )
    maxY = partRes.maxY
    if (maxY > this.maxPageY) {
      // 如果当前零件放不下了，那么就要新建页面
      // 把原本的零件用白色矩形隐藏起啦哎
      this.writeBlockBg(this.currentY, maxY - originY + 2, '#ffffff')
      // 添加新的页面
      await this.appendNewPage()
      // 重新再画当前的零件
      const res = await this.writePart(
        index,
        part,
        this.currentY,
        index % 2 === 0 ? 'rgb(250, 250, 250)' : '#ffffff'
      )
      // 重新赋值
      maxY = res.maxY
      originY = this.currentY
    }
    if (index % 2 === 0) {
      this.writeBlockBg(this.currentY, maxY - originY + 2)
    } else {
      this.writeBlockBg(this.currentY, maxY - originY + 2, '#ffffff')
    }
    this.currentY = originY
  }
  async writePart(index, part, y, bg) {
    this.doc.setFontSize(6)
    this.doc.setFont('BaseBoldFont')
    this.doc.text(index + 1 + '', this.partsTh[0], y + 10, null, null, 'left')

    let img = ''
    let imgBase64 = ''
    let imgUrl = ''
    let imgH = 10

    if (part.img_url) {
      try {
        img = await fetch(this.ossUrl + part.img_url).then(res => {
          if (res.status === 200) {
            return res.blob()
          } else {
            return Promise.reject(res)
          }
        })
      } catch (error) {
        console.log(error)
      }
    }

    if (img) {
      imgBase64 = await this.blobToDataURI(img)
      imgUrl = await this.getImgCanvas(imgBase64, bg)
      const w = imgUrl.msg.ratio * imgH
      const ml = (15 - w) / 2
      // 辅助线
      // this.doc.setDrawColor(255, 0, 0);
      // this.doc.rect(this.partsTh[0] + 5, y + 2, 15, 10);
      this.doc.addImage(
        imgUrl.img,
        this.partsTh[0] + 5 + ml,
        y + 2,
        w,
        imgH,
        'part_' + '' + part.id + '' + (index + 1),
        'FAST'
      )
    }

    this.doc.setFontSize(4)
    this.doc.setFont('BaseFont')
    this.doc.text(
      part.size + ' mm',
      this.partsTh[0] + 5,
      y + 14,
      null,
      null,
      'left'
    )

    this.doc.setFontSize(6)
    const name_str = multilineText(
      this.doc,
      part.name ,
      this.partsTh[1],
      y + 4,
      80,
      99,
      3
    );
    this.doc.setFontSize(6)
    // this.doc.text(part.tech_id + '/' + part.mat_id, this.partsTh[1], y + 7, null, null, "left")

    this.doc.setFontSize(6)
    
    const finishStr = part.finish_data_arr.map(item => {
      return item.label && item.name ? item.label : item.name
    }).join(' / ')

    // 处理 RA 表处 为 Other的情况
    function removeOtherPrefix(str) {
      if(!str) return ''
      return str.replace(/other:/g, '');
    }
    
    const sur = multilineText(
      this.doc,
      part.tech_id + ' / ' + part.mat_id + (finishStr? ' / ' + finishStr : '') + (part.smooth_spi_name? ' / '+ removeOtherPrefix(part.smooth_spi_name) : ''),
      this.partsTh[1],
      name_str.y + 3,
      80,
      99,
      3
    )
    // this.doc.text(part.sur_id, this.partsTh[1], sur.y + 10, null, null, "left")

    this.doc.setFontSize(4)

    let descLineH = 2.5
    this.doc.text(
      `• General tolerance: ${part.tolerance}`,
      this.partsTh[1],
      sur.y + descLineH,
      null,
      null,
      'left'
    )
    descLineH += 2.5

    this.doc.text(
      `• Tightest tolerance: ${part.anotherTolerance}`,
      this.partsTh[1],
      sur.y + descLineH,
      null,
      null,
      'left'
    )
    descLineH += 2.5

    if (this.data.order.process_type !== 2) {
      this.doc.text(
        `• Threads and tapped holes: ${part.spiricle || '-'}`,
        this.partsTh[1],
        sur.y + descLineH,
        null,
        null,
        'left'
      )
      descLineH += 2.5
    }

    this.doc.setFontSize(6)
    this.doc.text(part.num + '', this.partsTh[2], y + 10, null, null, 'center')

    this.doc.text(
      part.ori_unit_price,
      this.partsTh[3],
      y + 10,
      null,
      null,
      'left'
    )
    this.doc.text(part.ori_price, this.partsTh[4], y + 10, null, null, 'left')

    return Promise.resolve({
      maxY: sur.y + 8 >= y + imgH + 7.5 ? sur.y + 8 : y + imgH + 7.5
    })
  }
  async writeMold(mold, y) {
    this.doc.setFontSize(6)
    this.doc.setFont('BaseBoldFont')
    const { img } = await this.getImgCanvas(moldImg, 'rgb(250, 250, 250)')
    this.doc.addImage(img, this.moldTh[0] + 2, y + 5, 10, 10, 'mold', 'NONE')
    // this.doc.addImage(moldImg, "JPEG", this.moldTh[0] + 2, y + 5, 10, 10);

    this.doc.setFontSize(6)
    this.doc.setFont('BaseFont')
    this.doc.text(mold.mould_no, this.moldTh[1], y + 10, null, null, 'left')

    this.doc.text(
      mold.mould_material,
      this.moldTh[2],
      y + 10,
      null,
      null,
      'center'
    )
    // this.doc.setDrawColor(255, 0, 0);
    // this.doc.rect(this.moldTh[2] - 6, y, 13, 18);

    this.doc.text(
      mold.mould_dura_max ? mold.mould_dura_max + ' K' : '',
      this.moldTh[3] + 3,
      y + 10,
      null,
      null,
      'left'
    )
    this.doc.text(
      mold.mould_hole_num,
      this.moldTh[4],
      y + 10,
      null,
      null,
      'center'
    )
    let mouldNum = null;
    let mouldPrice = null;
    let totalMouldPrice = null;
    if(this.data.order.is_return) {
      mouldNum = '-'
      mouldPrice = '-'
      totalMouldPrice = '-';
    }else {
      mouldNum = mold.mould_num
      mouldPrice = mold.mould_price
      totalMouldPrice = mold.mould_total_price
    }
    this.doc.text(
      mouldNum + '',
      this.moldTh[5],
      y + 10,
      null,
      null,
      'center'
    )
    this.doc.text(
      mouldPrice + '',
      this.moldTh[6] + 1,
      y + 10,
      null,
      null,
      'left'
    )
    this.doc.text(
      totalMouldPrice + '',
      this.moldTh[7] + 1,
      y + 10,
      null,
      null,
      'left'
    )
    return Promise.resolve()
  }
  // 写底部页脚
  writeFooter(cb) {
    this.doc.setLineWidth(0.5)
    this.doc.setDrawColor(0, 0, 0)
    this.doc.line(195, 285, this.baseX, 285)

    this.doc.setFontSize(10)
    this.doc.setFont('BaseFont')
    this.doc.setTextColor(0, 0, 0)

    this.doc.textWithLink('www.rapiddirect.com', this.baseX, 290, {
      url: 'https://www.rapiddirect.com/'
    })

    this.doc.text(
      `Page:${this.currentPage}/${this.total}`,
      195,
      290,
      null,
      null,
      'right'
    )

    if (this.currentPage !== 1) {
      setTimeout(() => {
        // 这里需要写递归不断向上写页脚
        this.currentPage -= 1
        this.doc.movePage(this.currentPage, this.currentPage + 1)
        this.writeFooter(cb)
      }, 100)
    } else {
      // 这里代表已经写完页脚，需要把位置改为原本的位置
      this.currentPage = this.total
      this.doc.movePage(1, this.total)
      cb()
    }
  }
  async writeData() {
    if ((this.data.order.process_type === 2 || this.data.order.is_return) && this.data.mould_data.length) {
      // 注塑
      for (let i = 0; i < this.data.mould_data.length; i++) {
        const item = this.data.mould_data[i]
        if (this.currentY > this.maxPageY) {
          await this.appendNewPage()
        }
        if (i !== 0) {
          this.currentY += 10
        }
        this.writeMoldTbHeader()
        this.currentY += 3
        this.writeBlockBg(this.currentY)
        await this.writeMold(item, this.currentY)
        this.currentY += 23
        const part = this.data.order.order_parts_list.filter(
          r => r.mould_id === item.id
        )
        for (let j = 0; j < part.length; j++) {
          const r = part[j]
          const k = j
          if (this.currentY > this.maxPageY) {
            await this.appendNewPage()
          }
          if (k === 0) {
            this.writePartTbHeader()
          }
          this.currentY += 3
          if (k % 2 === 0) {
            this.writeBlockBg(this.currentY)
          }
          await this.writePartBlockBg(k, r)
          const res = await this.writePart(
            k,
            r,
            this.currentY,
            k % 2 === 0 ? 'rgb(250, 250, 250)' : '#ffffff'
          )
          this.currentY = res.maxY + 2
          if (this.currentY > this.maxPageY) {
            await this.appendNewPage()
          }
        }
        this.currentY += 8
      }
    } else {
      // 非注塑
      this.writePartTbHeader()
      this.currentY += 3
      for (let i = 0; i < this.data.order.order_parts_list.length; i++) {
        const index = i
        const item = this.data.order.order_parts_list[i]
        await this.writePartBlockBg(index, item)
        const res = await this.writePart(
          index,
          item,
          this.currentY,
          index % 2 === 0 ? 'rgb(250, 250, 250)' : '#ffffff'
        )
        this.currentY = res.maxY + 2
        if (this.currentY > this.maxPageY) {
          await this.appendNewPage()
        }
      }
      // this.data.order.order_parts_list.forEach((item, index) => {
      // })
    }
    return Promise.resolve()
  }
  async writeMoneyMsg() {
    this.doc.setFont('BaseFont')
    if (this.data.order.process_type === 2) {
      this.currentY += 6
      this.doc.setFontSize(7)
      this.doc.text('Sub-total', 100, this.currentY, null, null, 'left')
      this.doc.text(
        'USD ' + this.data.order.sub_total + '',
        190,
        this.currentY,
        null,
        null,
        'right'
      )
    } else {
      this.currentY += 6
      this.doc.setFontSize(7)
      this.doc.text('Parts amount', 100, this.currentY, null, null, 'left')
      this.doc.text(
        'USD ' + this.data.order.parts_amount + '',
        190,
        this.currentY,
        null,
        null,
        'right'
      )
    }
    this.currentY += 6
    if (this.currentY > this.maxPageY) {
      await this.appendNewPage()
    }
    this.doc.text('Shipping cost', 100, this.currentY, null, null, 'left')
    this.doc.text(
      this.data.order.transport_type === -1
        ? 'EXW'
        : this.data.order.transport_type === 10
        ? 'FOB Shenzhen'
        : this.data.order.is_shipping_freight && this.data.order.is_shipping_freight == 1 && this.data.order.total_addition_freight == 0? 'Billed Separately' : 'USD ' + this.data.order.ori_usd_freight + '',
      190,
      this.currentY,
      null,
      null,
      'right'
    )
    // 追加费用
    if (this.data.other_list) {
      for (const item of this.data.other_list) {
        this.currentY += 6;
        if (this.currentY > this.maxPageY) {
          await this.appendNewPage();
        }
        this.doc.text(`Other (${item.name})`, 100, this.currentY, null, null, 'left');
        this.doc.text(
          'USD ' + item.amount + '',
          190,
          this.currentY,
          null,
          null,
          'right'
        );
      }
    }
    // 折扣费用
    if (+this.data.order.discount_money) {
      this.currentY += 6
      if (this.currentY > this.maxPageY) {
        await this.appendNewPage()
      }
      this.doc.text('Total discount', 100, this.currentY, null, null, 'left')
      this.doc.text(
        '-USD ' + this.data.order.discount_money + '',
        190,
        this.currentY,
        null,
        null,
        'right'
      )
    }
    // 退款费用
    if(parseFloat(this.data.order.return_amount)){
      this.currentY += 6
      if (this.currentY > this.maxPageY) {
        await this.appendNewPage()
      }
      this.doc.text('Refunded', 100, this.currentY, null, null, 'left')
      this.doc.text(
        '-USD ' + this.data.order.return_amount + '',
        190,
        this.currentY,
        null,
        null,
        'right'
      )
    }
    if (this.data.order.process_type === 2) {
      if (([0,1,9,2,6].includes(this.data.order.first_mould_pay_type) || this.data.order.total_price > 10000)) {
        this.currentY += 6

        if (this.currentY > this.maxPageY) {
          await this.appendNewPage()
        }
        
        if (this.data.mould_data.length) {
          this.doc.text(
            `Total advance payment  \n ${this.data.order.total_advance_payment_str}`,
            100,
            this.currentY,
            null,
            null,
            'left'
          )
          this.doc.text(
            'USD ' + this.data.order.total_advance_payment + '',
            190,
            this.currentY + 1,
            null,
            null,
            'right'
          )
          this.currentY += 10
          if (this.currentY > this.maxPageY) {
            await this.appendNewPage()
          }
          this.doc.text(
            'Total balance payment',
            100,
            this.currentY,
            null,
            null,
            'left'
          )
          this.doc.text(
            'USD ' + this.data.order.total_balance_payment + '',
            190,
            this.currentY,
            null,
            null,
            'right'
          )
        } else {
          this.doc.text(
            `Total advance payment  \n ${this.data.order.total_advance_payment_str}`,
            100,
            this.currentY,
            null,
            null,
            'left'
          )
          this.doc.text(
            'USD ' + this.data.order.total_balance_payment + '',
            190,
            this.currentY + 1,
            null,
            null,
            'right'
          )
          this.currentY += 5
        }
      }
    }
    this.currentY += 3
    // 判断是否是分批  查看下单前的账单是否结算  结算后展示两行  未结算展示一行
    let rowMultiple = 1
    let batchBilling = []
    if(this.data?.bill_list && this.data?.bill_list.length && this.data.bill_list.some(item => item.bill_status !== 3)){
      // 获取预付款账单结算状态 
      const prepaymentBill = this.data.bill_list.find(item => item.batches_node === 1)
      if(prepaymentBill.bill_status === 3){
        rowMultiple = 2
        let proportionPaid = 0 // 已支付比例
        let amountAlreadyPaid = 0 // 已支付金额
        let batchesNodeMap = new Map([
          [2,'Payable amount before shipment'],
          [3,'Balance after shipment'],
          [4,'Balance after receipt'],
        ])
        let obj = {}
        const bill_list_num = this.data.bill_list.length
        for(const item of this.data.bill_list){
          if(item.bill_status === 3){
            proportionPaid += parseFloat(item.rate)
            amountAlreadyPaid += parseFloat(item.total_amount)
          }else{
            if(bill_list_num === 2 && item.batches_node === 2){
              obj.label = `Balance before shipment (${+item.rate}%)`
              obj.value = 'USD ' + item.total_amount
            }else{
              obj.label = `${batchesNodeMap.get(item.batches_node)} (${+item.rate}%)`
              obj.value = 'USD ' + item.total_amount
            }
            break
          }
        }
        batchBilling.push(
          {
            label: `Prepaid(${+proportionPaid}%)`,
            value: 'USD ' + amountAlreadyPaid
          },
          obj
        )
      }else{
        rowMultiple = 1.5
        batchBilling.push(
          {
            label: `Deposit(${+prepaymentBill?.rate}%)`,
            value: 'USD ' + prepaymentBill.total_amount
          }
        )
      }
    }
    if (this.currentY + parseFloat(12.6 * rowMultiple)  > this.maxPageY) {
      await this.appendNewPage()
    }
    this.doc.setDrawColor(255, 255, 255)
    this.doc.setFillColor(234, 84, 63)
    this.doc.rect(95, this.currentY, 100, 12.6 * rowMultiple, 'FD')
    this.doc.setFontSize(9)
    this.doc.setFont('BaseBoldFont')
    this.doc.setTextColor(255, 255, 255)
    const { grand_total_type } = this.data.order;
    this.doc.text(
      `Grand Total (${grand_total_type || 'DAP'})`,
      100,
      this.currentY + 7.5,
      null,
      null,
      'left'
    )
    this.doc.text(
      'USD ' + this.data.order.total_price + '',
      190,
      this.currentY + 7.5,
      null,
      null,
      'right'
    )
    // 分批支付账单
    if(batchBilling.length){
      batchBilling.forEach(item => {
        this.currentY += 6
        this.doc.text(
          item.label,
          100,
          this.currentY + 7.5,
          null,
          null,
          'left'
        )
        this.doc.text(
          item.value,
          190,
          this.currentY + 7.5,
          null,
          null,
          'right'
        )
      })
    }
    return Promise.resolve()
  }
  async writeOrderMsg() {
    const rightX = 32
    if (this.currentY > this.maxPageY) {
      await this.appendNewPage()
    }
    this.doc.setFontSize(10)
    this.doc.setFont('BaseBoldFont')
    this.doc.setTextColor(0, 0, 0)

    this.doc.text(
      'Terms & Conditions:',
      this.baseX,
      this.currentY,
      null,
      null,
      'left'
    )

    this.doc.setFontSize(7)

    if (this.data.order.process_type === 2) {
      this.currentY += 6

      if (this.currentY > this.maxPageY) {
        await this.appendNewPage()
        this.doc.setFontSize(7)
      }

      this.doc.setFont('BaseBoldFont')
      this.doc.text(
        'Mold payment term:',
        this.baseX,
        this.currentY,
        null,
        null,
        'left'
      )
      this.doc.setFont('BaseFont')
      this.doc.text(`${
          ([0,1,9,2,6].includes(this.data.order.first_mould_pay_type) || this.data.order.total_price > 10000) ?
          (this.data.order.mold_payment_term || '') :
          this.data.order.payment_term
        }`,
        this.baseX + rightX,
        this.currentY,
        null,
        null,
        'left'
      )

      this.currentY += 6

      if (this.currentY > this.maxPageY) {
        await this.appendNewPage()
        this.doc.setFontSize(7)
      }

      this.doc.setFont('BaseBoldFont')
      this.doc.text(
        'Part payment term:',
        this.baseX,
        this.currentY,
        null,
        null,
        'left'
      )
      this.doc.setFont('BaseFont')
      this.doc.text(`${
          ([0,1,9,2,6].includes(this.data.order.first_mould_pay_type) || this.data.order.total_price > 10000) ?
          (this.data.order.parts_payment_term || '') :
          this.data.order.payment_term
        }`,
        this.baseX + rightX,
        this.currentY,
        null,
        null,
        'left'
      )
    }

    this.currentY += 6

    if (this.currentY > this.maxPageY) {
      await this.appendNewPage()
      this.doc.setFontSize(7)
    }

    if (this.data.order.process_type !== 2) {
      this.doc.setFont('BaseBoldFont')
      this.doc.text(
        'Payment Term:',
        this.baseX,
        this.currentY,
        null,
        null,
        'left'
      )
      this.doc.setFont('BaseFont')
      this.doc.text(
        this.data.order.payment_term || '',
        this.baseX + rightX,
        this.currentY,
        null,
        null,
        'left'
      )

      this.currentY += 6

      if (this.currentY > this.maxPageY) {
        await this.appendNewPage()
        this.doc.setFontSize(7)
      }
    }

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Bank:', this.baseX, this.currentY, null, null, 'left')
    this.doc.setFont('BaseFont')
    this.doc.text(
      this.data.other.bank||'',
      this.baseX + rightX,
      this.currentY,
      null,
      null,
      'left'
    )

    this.currentY += 6

    if (this.currentY > this.maxPageY) {
      await this.appendNewPage()
    }

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Bank Add:', this.baseX, this.currentY, null, null, 'left')
    this.doc.setFont('BaseFont')

    const bankAdd = this.splitStr(this.data.other.bank_add, 120)
    this.doc.text(bankAdd.join('\n'), this.baseX + rightX, this.currentY, {
      align: 'left',
      lineHeightFactor: 1.8
    })

    this.currentY += 6 * bankAdd.length

    if (this.currentY > this.maxPageY) {
      await this.appendNewPage()
      this.doc.setFontSize(7)
    }

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Bank Swift:', this.baseX, this.currentY, null, null, 'left')
    this.doc.setTextColor(234, 84, 63)
    this.doc.setFont('BaseFont')
    this.doc.text(
      this.data.other.bank_swift||'',
      this.baseX + rightX,
      this.currentY,
      null,
      null,
      'left'
    )

    this.currentY += 6

    if (this.currentY > this.maxPageY) {
      await this.appendNewPage()
      this.doc.setFontSize(7)
    }

    this.doc.setTextColor(0, 0, 0)
    this.doc.setFont('BaseBoldFont')
    this.doc.text('Beneficiary:', this.baseX, this.currentY, null, null, 'left')
    this.doc.setFont('BaseFont')
    this.doc.text(
      this.data.other.beneficiary||'',
      this.baseX + rightX,
      this.currentY,
      null,
      null,
      'left'
    )

    this.currentY += 6

    if (this.currentY > this.maxPageY) {
      await this.appendNewPage()
      this.doc.setFontSize(7)
    }

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Address:', this.baseX, this.currentY, null, null, 'left')
    this.doc.setFont('BaseFont')
    // const address = this.splitStr(this.data.other.address, 104)
    const address = multilineText(
      this.doc,
      this.data.other.address||'',
      this.baseX + rightX,
      this.currentY,
      150,
      99
    )
    // this.doc.text(address. ('\n'), this.baseX + rightX, this.currentY, { align: 'left', lineHeightFactor: 1.8 });

    this.currentY = address.y + 6

    if (this.currentY > this.maxPageY) {
      await this.appendNewPage()
      this.doc.setFontSize(7)
    }

    this.doc.setFont('BaseBoldFont')
    this.doc.text('A/C No.:', this.baseX, this.currentY, null, null, 'left')
    this.doc.setTextColor(234, 84, 63)
    this.doc.setFont('BaseFont')
    this.doc.text(
      this.data.other.ac_no,
      this.baseX + rightX,
      this.currentY,
      null,
      null,
      'left'
    )

    this.currentY += 6

    if (this.currentY > this.maxPageY) {
      await this.appendNewPage()
      this.doc.setFontSize(7)
    }

    this.doc.setTextColor(0, 0, 0)
    this.doc.setFont('BaseBoldFont')
    this.doc.text('Paypal Ac', this.baseX, this.currentY, null, null, 'left')
    this.doc.setFont('BaseFont')
    this.doc.text(
      this.data.other.paypal_ac,
      this.baseX + rightX,
      this.currentY,
      null,
      null,
      'left'
    )

    return Promise.resolve()
  }
  // 添加新页面，并把y轴位置初始化
  async appendNewPage() {
    this.doc.addPage('a4', 'p')
    await this.writeTop()
    this.currentY = 50
    this.currentPage += 1
    this.total += 1
    return Promise.resolve()
  }
  //  二进制转base64
  blobToDataURI(blob) {
    const reader = new FileReader()
    return new Promise(resolve => {
      reader.onload = function(e) {
        resolve(reader.result)
      }
      reader.readAsDataURL(blob)
    })
  }

    // 公共初始化方法
    initializeFonts() {
      this.doc.addFileToVFS('BaseBoldFont.ttf', baseBoldFont);
      this.doc.addFont('BaseBoldFont.ttf', 'BaseBoldFont', 'normal');
      this.doc.addFileToVFS('BaseFont.ttf', baseFont);
      this.doc.addFont('BaseFont.ttf', 'BaseFont', 'normal');
      this.doc.addFileToVFS('fangzhengfangsong', fangzhengfangsong);
      this.doc.addFont('fangzhengfangsong', 'fangzhengfangsong', 'normal');
      this.doc.addFileToVFS('heiti', heiti);
      this.doc.addFont('heiti', 'heiti', 'normal');
    }
  
    // 处理流程类型
    handleProcessType() {
      if (this.data.order.process_type === 2 && this.data.order.is_return) {
        this.data.order.process_type = 1;
      }
    }
  
    // 公共文档内容生成
    async writeDocumentContents() {
      await this.writeTop();
      const leftY = this.writeLeftMsg();
      const rightY = this.writeRightMsg();
      this.currentY = (leftY > rightY ? leftY : rightY) + 15;
      await this.writeData();
      await this.writeMoneyMsg();
      this.currentY += 30;
      await this.writeOrderMsg();
    }
    // 初始化订单数据
    setupOrderData(data) {
      this.data = data;
      this.handleProcessType();
    }
  async run() {
    const { data } = await this.request;
    this.initializeFonts();
    this.setupOrderData(data);
    await this.writeDocumentContents();
    return new Promise(resolve => {
      this.writeFooter(() => {
        this.doc.save(`${this.title === 'INVOICE'?'Invoice':'Proforma Invoice'} - ${this.data.order.order_no}.pdf`);
        resolve();
      });
    });
  }

}
export default PI
